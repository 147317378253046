import React, { useState, useEffect } from "react";
import axios from "axios";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

const Main = () => {
  const [coviddata, setcoviddata] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.covid19api.com/summary")
      .then((res) => {
        console.log(res.data);
        setcoviddata(res.data.Countries);
      })
      .catch((err) => {
        console.log(err);
      });

    $(document).ready(() => {
      $("#myTable").DataTable();
    });
  }, []);

  const tableData = coviddata.map((obj) => {
    return (
      <tr>
        <td>{obj.Country}</td>
        <td>{obj.TotalConfirmed}</td>
        <td>{obj.TotalConfirmed - obj.TotalRecovered}</td>
        <td>{obj.TotalRecovered}</td>
        <td>{obj.TotalDeaths}</td>
      </tr>
    );
  });

  return (
    <div>
     <center><h1>Covid Statistics Using React</h1></center> 

      <div className="row justify-content-center">
        <div className="col-md-8">
          <table id="myTable" className="table table-dark">
            <thead>
              <tr>
                <th>country</th>
                <th>confirmed</th>
                <th>active</th>
                <th>recovered</th>
                <th>deaths</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Main;
