
import './App.css';
import Main from './Main';
import bootstrap from '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <Main/>
  );
}
 
export default App;
